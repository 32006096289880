import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import type { DialogProps } from "@/contexts/dialogContext";
import { api } from "@/lib/api";
import { fromSun } from "@/utils/convertCurrency";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

export const TransactionsDialog: React.FC<DialogProps<"transactions">> = ({
	isOpen,
	onClose,
	payload,
}) => {
	const { t } = useTranslation();
	const { data, isLoading, error } = useQuery({
		queryKey: ["transactions", payload.id],
		queryFn: async () => {
			const response = await api.transactions({ orderId: payload.id }).get();

			return response.data;
		},
	});

	if (isLoading) {
		return <div />;
	}
	if (error || !data) {
		toast.error(t("errorLoadingTransactions"));
		return <div />;
	}

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[800px]">
				<DialogHeader>
					<DialogTitle>{t("transactions")}</DialogTitle>
				</DialogHeader>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>{t("tx")}</TableHead>
							<TableHead>{t("address")}</TableHead>
							<TableHead>{t("delegated")}</TableHead>
							<TableHead>{t("payment")}</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{data.length === 0 ? (
							<TableRow>
								<TableCell colSpan={4}>{t("noTransactions")}</TableCell>
							</TableRow>
						) : (
							data.map((transaction) => (
								<TableRow key={transaction.id}>
									<TableCell className="font-mono">
										<a
											className="text-muted-foreground underline"
											target="_blank"
											rel="noreferrer"
											href={`https://tronscan.org/#/transaction/${transaction.id}`}
										>
											{transaction.id.substring(0, 10)}...
										</a>
									</TableCell>
									<TableCell className="font-mono">
										{transaction.originAddress}
									</TableCell>
									<TableCell>
										{Number(fromSun(transaction.frozen)).toLocaleString()} TRX
									</TableCell>
									<TableCell>
										{fromSun(transaction.reward).toLocaleString()} TRX
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</DialogContent>
		</Dialog>
	);
};
