import { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { api } from "@/lib/api";
import { toast } from "sonner";
import { MARKET_DATA_KEY, useMarketData } from "@/hooks/useMarketData";
import { FormItem } from "./formItem";
import { useDialogContext } from "@/contexts/dialogContext";
import { queryClient } from "@/lib/queryClient";
import { useTranslation } from "react-i18next";

const formSchema = z.object({
	address: z.string().min(1, "Address is required"),
	energyPrices: z.array(
		z.object({
			minDuration: z.number().positive("Minimum duration must be positive"),
			minPrice: z.number().positive("Minimum price must be positive"),
			suggestedPrice: z.number().positive("Suggested price must be positive"),
		}),
	),
	bandwidthPrices: z.array(
		z.object({
			minDuration: z.number().positive("Minimum duration must be positive"),
			minPrice: z.number().positive("Minimum price must be positive"),
			suggestedPrice: z.number().positive("Suggested price must be positive"),
		}),
	),
	minEnergy: z.number().positive("Minimum energy must be positive"),
	suggestedEnergy: z.number().positive("Suggested energy must be positive"),
	minBandwidth: z.number().positive("Minimum bandwidth must be positive"),
	suggestedBandwidth: z
		.number()
		.positive("Suggested bandwidth must be positive"),
	minFillEnergy: z.number().positive("Minimum fill energy must be positive"),
	minFillBandwidth: z
		.number()
		.positive("Minimum fill bandwidth must be positive"),
	fillOrderAward: z.number().positive("Fill order award must be positive"),
	suggestedDuration: z.number().positive("Suggested duration must be positive"),
	durations: z.array(z.number().positive("Duration must be positive")),
	reward: z.number().positive("Reward must be positive"),
});

type FormValues = z.infer<typeof formSchema>;

export const AdminMarketDataForm: React.FC = () => {
	const { openDialog } = useDialogContext();
	const { data, isLoading, error } = useMarketData();
	const { control, handleSubmit, reset } = useForm<FormValues>({
		resolver: zodResolver(formSchema),
	});
	const { t } = useTranslation();

	useEffect(() => {
		if (data) {
			reset({
				address: data.address,
				energyPrices: data.price.energyPrices,
				bandwidthPrices: data.price.bandwidthPrices,
				minEnergy: data.order.minEnergy,
				suggestedEnergy: data.order.suggestedEnergy,
				minBandwidth: data.order.minBandwidth,
				suggestedBandwidth: data.order.suggestedBandwidth,
				minFillEnergy: data.order.minFillEnergy,
				minFillBandwidth: data.order.minFillBandwidth,
				fillOrderAward: data.order.fillOrderAward,
				suggestedDuration: data.order.suggestedDuration,
				durations: data.order.durations,
				reward: data.referral.reward,
			});
		}
	}, [data, reset]);

	const {
		fields: energyPriceFields,
		append: appendEnergyPrice,
		remove: removeEnergyPrice,
	} = useFieldArray<FormValues>({
		control,
		name: "energyPrices",
	});

	const {
		fields: bandwidthPriceFields,
		append: appendBandwidthPrice,
		remove: removeBandwidthPrice,
	} = useFieldArray<FormValues>({
		control,
		name: "bandwidthPrices",
	});

	const onSubmit = handleSubmit(
		(values) => {
			console.log(values);
			openDialog(
				"password",
				{
					title: t("enterPassword"),
					description: t("enterPasswordDescription"),
					confirmButtonText: t("confirm"),
				},
				async (password) => {
					const res = await api.market.update.put(values, {
						headers: {
							"x-password": password,
						},
					});
					res.status === 200
						? toast.success(t("marketDataUpdated"))
						: toast.error(t("marketDataUpdateFailed"));
					queryClient.invalidateQueries({
						queryKey: [MARKET_DATA_KEY],
					});
				},
			);
		},
		(errors) => {
			console.log("Form errors:", errors);
			toast.error(t("fixFormErrors"));
		},
	);

	if (isLoading) {
		return <p>{t("loading")}</p>;
	}

	if (error) {
		return <p>{t("errorLoadingMarketData")}</p>;
	}

	return (
		<form onSubmit={onSubmit} className="space-y-6 text-card-foreground">
			<div className="space-y-4">
				<h3 className="font-semibold text-lg">{t("basicInformation")}</h3>
				<FormItem label={t("address")} tooltip={t("contractAddressTooltip")}>
					<Controller
						name="address"
						control={control}
						render={({ field }) => (
							<Input
								{...field}
								onChange={(e) => field.onChange(e.target.value)}
								placeholder={t("address")}
							/>
						)}
					/>
				</FormItem>
			</div>
			<div className="space-y-4">
				<h3 className="font-semibold text-lg">{t("orderSettings")}</h3>
				<div className="grid grid-cols-2 gap-4">
					<FormItem label={t("minEnergy")} tooltip={t("minEnergyTooltip")}>
						<Controller
							name="minEnergy"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									onChange={(e) => field.onChange(Number(e.target.value))}
									type="number"
									placeholder={t("minEnergy")}
								/>
							)}
						/>
					</FormItem>
					<FormItem
						label={t("suggestedEnergy")}
						tooltip={t("suggestedEnergyTooltip")}
					>
						<Controller
							name="suggestedEnergy"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									onChange={(e) => field.onChange(Number(e.target.value))}
									type="number"
									placeholder={t("suggestedEnergy")}
								/>
							)}
						/>
					</FormItem>
				</div>
				<div className="grid grid-cols-2 gap-4">
					<FormItem label={t("minBandwidth")} tooltip={t("minBandwidthTooltip")}>
						<Controller
							name="minBandwidth"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									onChange={(e) => field.onChange(Number(e.target.value))}
									type="number"
									placeholder={t("minBandwidth")}
								/>
							)}
						/>
					</FormItem>
					<FormItem
						label={t("suggestedBandwidth")}
						tooltip={t("suggestedBandwidthTooltip")}
					>
						<Controller
							name="suggestedBandwidth"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									onChange={(e) => field.onChange(Number(e.target.value))}
									type="number"
									placeholder={t("suggestedBandwidth")}
								/>
							)}
						/>
					</FormItem>
				</div>
				<div className="grid grid-cols-2 gap-4">
					<FormItem label={t("minFillEnergy")} tooltip={t("minFillEnergyTooltip")}>
						<Controller
							name="minFillEnergy"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									onChange={(e) => field.onChange(Number(e.target.value))}
									type="number"
									placeholder={t("minFillEnergy")}
								/>
							)}
						/>
					</FormItem>
					<FormItem
						label={t("minFillBandwidth")}
						tooltip={t("minFillBandwidthTooltip")}
					>
						<Controller
							name="minFillBandwidth"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									onChange={(e) => field.onChange(Number(e.target.value))}
									type="number"
									placeholder={t("minFillBandwidth")}
								/>
							)}
						/>
					</FormItem>
				</div>
				<div className="grid grid-cols-2 gap-4">
					<FormItem label={t("fillOrderAward")} tooltip={t("fillOrderAwardTooltip")}>
						<Controller
							name="fillOrderAward"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									onChange={(e) => field.onChange(Number(e.target.value))}
									type="number"
									placeholder={t("fillOrderAward")}
								/>
							)}
						/>
					</FormItem>
					<FormItem
						label={t("suggestedDuration")}
						tooltip={t("suggestedDurationTooltip")}
					>
						<Controller
							name="suggestedDuration"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									onChange={(e) => field.onChange(Number(e.target.value))}
									type="number"
									placeholder={t("suggestedDuration")}
								/>
							)}
						/>
					</FormItem>
				</div>
			</div>
			{/* Energy Prices Section */}
			<div className="space-y-4">
				<h3 className="font-semibold text-lg">{t("energyPrices")}</h3>
				{energyPriceFields.map((field, index) => (
					<div key={field.id} className="grid grid-cols-4 gap-4">
						<FormItem label={t("minDuration")} tooltip={t("minDurationTooltip")}>
							<Controller
								name={`energyPrices.${index}.minDuration`}
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										onChange={(e) => field.onChange(Number(e.target.value))}
										type="number"
										placeholder={t("minDuration")}
									/>
								)}
							/>
						</FormItem>
						<FormItem label={t("minPrice")} tooltip={t("minPriceTooltip")}>
							<Controller
								name={`energyPrices.${index}.minPrice`}
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										onChange={(e) => field.onChange(Number(e.target.value))}
										type="number"
										placeholder={t("minPrice")}
									/>
								)}
							/>
						</FormItem>
						<FormItem
							label={t("suggestedPrice")}
							tooltip={t("suggestedPriceTooltip")}
						>
							<Controller
								name={`energyPrices.${index}.suggestedPrice`}
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										onChange={(e) => field.onChange(Number(e.target.value))}
										type="number"
										placeholder={t("suggestedPrice")}
									/>
								)}
							/>
						</FormItem>
						<Button
							type="button"
							variant="destructive"
							onClick={() => removeEnergyPrice(index)}
						>
							{t("remove")}
						</Button>
					</div>
				))}
				<Button
					type="button"
					onClick={() =>
						appendEnergyPrice({
							minDuration: 0,
							minPrice: 0,
							suggestedPrice: 0,
						})
					}
				>
					{t("addEnergyPrice")}
				</Button>
			</div>
			{/* Bandwidth Prices Section */}
			<div className="space-y-4">
				<h3 className="font-semibold text-lg">{t("bandwidthPrices")}</h3>
				{bandwidthPriceFields.map((field, index) => (
					<div key={field.id} className="grid grid-cols-4 gap-4">
						<FormItem label={t("minDuration")} tooltip={t("minDurationTooltip")}>
							<Controller
								name={`bandwidthPrices.${index}.minDuration`}
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										onChange={(e) => field.onChange(Number(e.target.value))}
										type="number"
										placeholder={t("minDuration")}
									/>
								)}
							/>
						</FormItem>
						<FormItem label={t("minPrice")} tooltip={t("minPriceTooltip")}>
							<Controller
								name={`bandwidthPrices.${index}.minPrice`}
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										onChange={(e) => field.onChange(Number(e.target.value))}
										type="number"
										placeholder={t("minPrice")}
									/>
								)}
							/>
						</FormItem>
						<FormItem
							label={t("suggestedPrice")}
							tooltip={t("suggestedPriceTooltip")}
						>
							<Controller
								name={`bandwidthPrices.${index}.suggestedPrice`}
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										onChange={(e) => field.onChange(Number(e.target.value))}
										type="number"
										placeholder={t("suggestedPrice")}
									/>
								)}
							/>
						</FormItem>
						<Button
							type="button"
							variant="destructive"
							onClick={() => removeBandwidthPrice(index)}
						>
							{t("remove")}
						</Button>
					</div>
				))}
				<Button
					type="button"
					onClick={() =>
						appendBandwidthPrice({
							minDuration: 0,
							minPrice: 0,
							suggestedPrice: 0,
						})
					}
				>
					{t("addBandwidthPrice")}
				</Button>
			</div>

			<div className="space-y-4">
				<h3 className="font-semibold text-lg">{t("durations")}</h3>
				<Controller
					name="durations"
					control={control}
					render={({ field }) => (
						<div className="space-y-2">
							{field.value?.map((duration, index) => (
								<div key={index} className="flex gap-4">
									<Input
										type="number"
										value={duration}
										onChange={(e) => {
											const newDurations = [...field.value];
											newDurations[index] = Number(e.target.value);
											field.onChange(newDurations);
										}}
										placeholder={t("duration")}
									/>
									<Button
										type="button"
										variant="destructive"
										onClick={() => {
											const newDurations = field.value.filter((_, i) => i !== index);
											field.onChange(newDurations);
										}}
									>
										{t("remove")}
									</Button>
								</div>
							))}
							<Button
								type="button"
								onClick={() => {
									field.onChange([...field.value, 0]);
								}}
							>
								{t("addDuration")}
							</Button>
						</div>
					)}
				/>
			</div>

			<Button type="submit" className="w-full">
				{t("updateMarketData")}
			</Button>
		</form>
	);
};
