import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslations from "./locales/en/translation.json";
import cnTranslations from "./locales/cn/translation.json";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: ["en", "cn"],
		fallbackLng: "en",
		debug: true,
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: [
				"querystring",
				"cookie",
				"localStorage",
				"navigator",
				"htmlTag",
				"path",
				"subdomain",
			],
			caches: ["localStorage", "cookie"],
		},
		resources: {
			en: {
				translation: enTranslations,
			},
			cn: {
				translation: cnTranslations,
			},
		},
	});

export default i18n;
