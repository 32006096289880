import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { Button } from "./ui/button";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

export const ConnectButton: React.FC<{ className?: string }> = ({
	className,
}) => {
	const { connect, connecting } = useWallet();
	const [t] = useTranslation();

	const handleConnect = async () => {
		try {
			await connect();
			toast.success("Wallet connected");
		} catch (error) {
			toast.error("Error connecting wallet");
			console.error("Error connecting wallet:", error);
		}
	};

	return (
		<Button
			variant="secondary"
			onClick={handleConnect}
			className={`flex flex-shrink-0 items-center gap-2 ${className}`}
			disabled={connecting}
		>
			<img src="/tronlink.jpg" alt="TronLink" className="h-5 rounded-sm" />
			{connecting ? t("connecting") : t("connectWallet")}
		</Button>
	);
};
