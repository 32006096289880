import { Building2, Globe, Mail, MessageCircle, Shield } from "lucide-react";
import { Link } from "@tanstack/react-router";
import { useDialogContext } from "@/contexts/dialogContext";
import { useTranslation } from "react-i18next";

export function Footer() {
	const { openDialog } = useDialogContext();
	const { t } = useTranslation();

	return (
		<footer className="border-t bg-card/80 py-6 text-card-foreground text-md shadow-sm backdrop-blur-sm">
			<div>
				{/* Logo Section */}
				<div className="flex flex-col items-center text-center">
					<img
						src="/logo-footer.webp"
						alt="Tronspace.vip Logo"
						className="mb-6 w-[300px]"
					/>

					<h2 className="mb-4 font-semibold text-xl">
						{t("footer.whatIsTronspace")}
					</h2>
					<p className="mb-8 max-w-2xl text-muted-foreground">
						{t("footer.description")}
					</p>

					<div className="mx-auto mb-12 grid max-w-4xl grid-cols-1 gap-8 md:grid-cols-3">
						<div className="flex flex-col items-center text-center">
							<div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-br from-blue-400 to-blue-600">
								<Building2 className="h-8 w-8 text-white" />
							</div>
							<h3 className="mb-2 font-semibold text-xl">{t("footer.economy")}</h3>
							<p className="text-muted-foreground">{t("footer.economyDescription")}</p>
						</div>

						<div className="flex flex-col items-center text-center">
							<div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-br from-orange-400 to-orange-600">
								<Globe className="h-8 w-8 text-white" />
							</div>
							<h3 className="mb-2 font-semibold text-xl">{t("footer.efficient")}</h3>
							<p className="text-muted-foreground">
								{t("footer.efficientDescription")}
							</p>
						</div>

						<div className="flex flex-col items-center text-center">
							<div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-br from-teal-400 to-green-400">
								<Shield className="h-8 w-8 text-white" />
							</div>
							<h3 className="mb-2 font-semibold text-xl">{t("footer.safety")}</h3>
							<p className="text-muted-foreground">{t("footer.safetyDescription")}</p>
						</div>
					</div>

					{/* Custom Divider */}
					<div className="mb-12 flex w-full justify-center">
						<div className="w-1/2 border-border border-t" />
					</div>

					{/* API Section */}
					<div className="mb-8 text-center">
						<h2 className="mb-2 font-semibold text-xl">{t("footer.automateApi")}</h2>
						<p className="text-muted-foreground">{t("footer.comingSoon")}</p>
					</div>
				</div>
				<div className="mx-auto w-full max-w-[1250px] border-t px-2 pt-8">
					<div className="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-4">
						<div className="space-y-3">
							<h3 className="font-semibold text-lg">{t("footer.about")}</h3>
							<ul className="space-y-2">
								<li>
									<p
										onClick={() => openDialog("faq", {})}
										className="cursor-pointer text-muted-foreground transition-colors hover:text-primary"
									>
										{t("footer.faq")}
									</p>
								</li>
								<li>
									<p
										className="cursor-pointer text-muted-foreground transition-colors hover:text-primary"
										onClick={() => openDialog("terms", {})}
									>
										{t("footer.terms")}
									</p>
								</li>
							</ul>
						</div>
						<div className="space-y-3">
							<h3 className="font-semibold text-lg">{t("footer.support")}</h3>
							<ul className="space-y-2">
								<li>
									<a
										href="mailto:support@tronspace.vip"
										className="inline-flex items-center gap-2 text-muted-foreground transition-colors hover:text-primary"
									>
										<Mail className="h-4 w-4" />
										{t("footer.emailSupport")}
									</a>
								</li>
								<li>
									<a
										href="https://t.me/tronspace_vip"
										target="_blank"
										rel="noopener noreferrer"
										className="inline-flex items-center gap-2 text-muted-foreground transition-colors hover:text-primary"
									>
										<MessageCircle className="h-4 w-4" />
										{t("footer.telegramSupport")}
									</a>
								</li>
							</ul>
						</div>
						<div className="space-y-3">
							<h3 className="font-semibold text-lg">{t("footer.resources")}</h3>
							<ul className="space-y-2">
								{/* <li>
									<Link
										href="/documentation"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Documentation
									</Link>
								</li> */}
								<li className="text-muted-foreground transition-colors hover:text-primary">
									{/* <Link
										href="/api"
										className="text-muted-foreground transition-colors hover:text-primary"
									> */}
									{t("footer.apiComingSoon")}
									{/* </Link> */}
								</li>
							</ul>
						</div>
					</div>
					<div className="mt-8 text-muted-foreground text-sm">
						<p>
							© {new Date().getFullYear()} Tronspace VIP.{" "}
							{t("footer.allRightsReserved")}
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
}
