import type React from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { FilePlusIcon, LightningBoltIcon } from "@radix-ui/react-icons";
import { formatDuration } from "@/utils/formatDuration";
import type { DialogProps } from "@/contexts/dialogContext";
import { FormItem } from "@/components/formItem";
import { Gauge } from "lucide-react";
import { LayoutDialog } from "./layoutDialog";
import { fromSun } from "@/utils/convertCurrency";
import { useTranslation } from "react-i18next";

export const CreateOrderDialog: React.FC<DialogProps<"createOrder">> = ({
	isOpen,
	onClose,
	onConfirm,
	payload,
}) => {
	const {
		resource,
		duration,
		price,
		targetAddress,
		partialFill,
		resourceAmount,
		totalPrice,
	} = payload;

	const { t } = useTranslation();

	return (
		<LayoutDialog
			isOpen={isOpen}
			onClose={onClose}
			icon={<FilePlusIcon className="h-8 w-8 text-primary-foreground" />}
			title={t("createOrder")}
			onConfirm={onConfirm}
			confirmText={t("confirmOrder")}
		>
			<p className="mb-4 text-center text-muted-foreground">
				{t("pleaseConfirmOrder")}
			</p>
			<div className="grid gap-4 py-4">
				<FormItem label={t("amount")}>
					<p>{resourceAmount.toLocaleString()}</p>
				</FormItem>
				<FormItem label={t("resource")}>
					<p>{resource === "ENERGY" ? "Energy" : "Bandwidth"}</p>
				</FormItem>
				<FormItem label={t("duration")}>
					<p>{formatDuration(duration)}</p>
				</FormItem>
				<FormItem label={t("price")}>
					<p>{price}</p>
				</FormItem>
				<FormItem label={t("targetAddress")}>
					<p className="text-sm">{targetAddress}</p>
				</FormItem>
				<FormItem label={t("options")}>
					<div className="flex items-center space-x-2">
						<Checkbox id="allowPartialFill" checked={partialFill} />
						<label
							htmlFor="allowPartialFill"
							className="font-medium text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
						>
							{t("allowPartialFill")}
						</label>
					</div>
				</FormItem>
				<div className="border-t pt-4">
					<div className="flex justify-between">
						<span>{resource === "ENERGY" ? "Energy" : "Bandwidth"}</span>
						<span className="flex items-center gap-1 text-primary">
							{resourceAmount.toLocaleString()}{" "}
							{resource === "ENERGY" ? (
								<LightningBoltIcon className="h-4 w-4" />
							) : (
								<Gauge className="h-4 w-4" />
							)}
						</span>
					</div>
					<div className="flex justify-between">
						<span className="flex items-center gap-1">{t("total")}</span>
						<span>{fromSun(totalPrice)} TRX</span>
					</div>
				</div>
			</div>
		</LayoutDialog>
	);
};
