import type React from "react";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { useTranslation } from "react-i18next";

interface FAQDialogProps {
	isOpen: boolean;
	onClose: () => void;
}

export const FAQDialog: React.FC<FAQDialogProps> = ({ isOpen, onClose }) => {
	const { t } = useTranslation();

	const faqItems = [
		{
			question: t("whatIsTronEnergy"),
			answer: t("tronEnergyDescription"),
		},
		{
			question: t("howToGetTronEnergy"),
			answer: t("getTronEnergyDescription"),
		},
		{
			question: t("howToRentEnergy"),
			answer: t("rentEnergyDescription"),
		},
		{
			question: t("energyArrivalTime"),
			answer: t("energyArrivalDescription"),
		},
		{
			question: t("energyRegenerationTime"),
			answer: t("energyRegenerationDescription"),
		},
		{
			question: t("apiAdvantages"),
			answer: t("apiAdvantagesDescription"),
		},
	];

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{t("faq")}</DialogTitle>
				</DialogHeader>
				<div className="prose prose-gray max-h-96 max-w-none overflow-y-auto">
					<Accordion type="single" collapsible className="w-full">
						{faqItems.map((item, index) => (
							<AccordionItem key={item.question} value={`item-${index}`}>
								<AccordionTrigger>{item.question}</AccordionTrigger>
								<AccordionContent>{item.answer}</AccordionContent>
							</AccordionItem>
						))}
					</Accordion>
				</div>
				<DialogFooter>
					<Button onClick={onClose}>{t("confirm")}</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
