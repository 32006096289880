import { createFileRoute, useLayoutEffect } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

export const Route = createFileRoute("/terms")({
	component: RouteComponent,
});

function RouteComponent() {
	const { t } = useTranslation();

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	});
	return (
		<div className="min-h-screen">
			<div className="mx-auto max-w-[1250px] px-4 py-12">
				<h1 className="mb-8 font-bold text-3xl">{t("terms.title")}</h1>

				<div className="prose prose-gray max-w-none">
					<p className="text-muted-foreground">{t("terms.intro")}</p>

					<h2 className="mt-8 font-bold text-2xl">{t("terms.generalConditions")}</h2>

					<div className="mt-6 space-y-6">
						<div>
							<h3 className="font-semibold text-xl">{t("terms.ageEligibility")}</h3>
							<p className="mt-2 text-muted-foreground">
								{t("terms.ageEligibilityText")}
							</p>
						</div>

						<div>
							<h3 className="font-semibold text-xl">{t("terms.serviceFees")}</h3>
							<p className="mt-2 text-muted-foreground">
								{t("terms.serviceFeesText")}
							</p>
						</div>

						<div>
							<h3 className="font-semibold text-xl">{t("terms.accountCreation")}</h3>
							<p className="mt-2 text-muted-foreground">
								{t("terms.accountCreationText")}
							</p>
						</div>

						<div>
							<h3 className="font-semibold text-xl">{t("terms.useOfServices")}</h3>
							<p className="mt-2 text-muted-foreground">
								{t("terms.useOfServicesText")}
							</p>
						</div>

						<div>
							<h3 className="font-semibold text-xl">
								{t("terms.intellectualProperty")}
							</h3>
							<p className="mt-2 text-muted-foreground">
								{t("terms.intellectualPropertyText")}
							</p>
						</div>

						<div>
							<h3 className="font-semibold text-xl">
								{t("terms.serviceInterruptions")}
							</h3>
							<p className="mt-2 text-muted-foreground">
								{t("terms.serviceInterruptionsText")}
							</p>
						</div>

						<div>
							<h3 className="font-semibold text-xl">{t("terms.security")}</h3>
							<p className="mt-2 text-muted-foreground">{t("terms.securityText")}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
