import type React from "react";
import { useEffect, useCallback, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LightningBoltIcon, RocketIcon } from "@radix-ui/react-icons";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { Gauge, WalletMinimal } from "lucide-react";
import { useDialogContext, type DialogProps } from "@/contexts/dialogContext";
import { LayoutDialog } from "./layoutDialog";
import { tronWeb } from "@/lib/tronWeb";
import { useMarketData } from "@/hooks/useMarketData";
import { api } from "@/lib/api";
import { useQuery } from "@tanstack/react-query";
import { toSun, fromSun } from "@/utils/convertCurrency";
import { formatDuration } from "@/utils/formatDuration";
import {
	getTronAccount,
	TRON_ACCOUNT_KEY,
	useTronAccount,
	type TronAccount,
} from "@/hooks/useTronWeb";
import { Separator } from "../ui/separator";
import { Button } from "../ui/button";
import { toast } from "sonner";
import { queryClient } from "@/lib/queryClient";
import { fromHex } from "@/utils/formatHex";
import { TABLE_QUERY_KEY } from "../ordersTable";
import { Checkbox } from "../ui/checkbox";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
	SelectLabel,
} from "../ui/select";
import type {
	ContractParamter,
	SignedTransaction,
	Transaction,
} from "node_modules/tronweb/lib/esm/types";
import { WALLET_REGEX } from "@/constants";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface FillOrderDialogProps extends DialogProps<"fillOrder"> {}

export const FillOrderDialog: React.FC<FillOrderDialogProps> = ({
	isOpen,
	onClose,
	payload,
}) => {
	const { openDialog } = useDialogContext();
	const { address, signTransaction, connected } = useWallet();
	const { data: accountData } = useTronAccount();
	const [multiSigAccount, setMultiSigAccount] = useState<TronAccount | null>(
		null,
	);
	const { data: marketData, isLoading: marketDataLoading } = useMarketData();
	const { t } = useTranslation();

	const {
		data: order,
		isLoading: orderLoading,
		error: orderError,
	} = useQuery({
		queryKey: ["order", payload.id],
		queryFn: async () => {
			const response = await api.orders({ id: payload.id }).get();
			return response.data;
		},
		enabled: isOpen,
	});

	const formSchema = z.object({
		stakeAmount: z.number(),
		payoutAddress: z.string(),
		isMultiSig: z.boolean(),
		signatureAddress: z.string().optional(),
		permissionId: z.number().optional(),
	});

	type FormValues = z.infer<typeof formSchema>;

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		setError,
		clearErrors,
		formState: { errors, isSubmitting },
	} = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			stakeAmount: 0,
			payoutAddress: address || "",
			isMultiSig: false,
			signatureAddress: "",
			permissionId: 0,
		},
	});

	const stakeAmount = watch("stakeAmount");
	const isMultiSig = watch("isMultiSig");
	const signatureAddress = watch("signatureAddress");
	const payoutAddress = watch("payoutAddress");

	useEffect(() => {
		if (address) {
			setValue("payoutAddress", address);
		}
		if (order && marketData?.order) {
			const minFill =
				order.resourceType === "ENERGY"
					? marketData.order.minFillEnergy
					: marketData.order.minFillBandwidth;

			setValue(
				"stakeAmount",
				order.settings?.partialFill ? minFill : order.resourceAmount,
			);
		}
	}, [address, order, marketData, setValue]);

	useEffect(() => {
		if (isMultiSig && signatureAddress && WALLET_REGEX.test(signatureAddress)) {
			console.log("Fetching multisig account");
			const fetchData = async () => {
				try {
					const data = await getTronAccount(signatureAddress, address || undefined);
					setMultiSigAccount(data);
				} catch (error) {
					setError("signatureAddress", {
						type: "manual",
						message: "Error fetching multisig account",
					});
				}
			};
			fetchData();
		}
	}, [isMultiSig, signatureAddress, address, setError]);

	const handleResourceAmountChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			// Parse the input value, removing any non-numeric characters
			const rawValue = event.target.value.replace(/[^0-9]/g, "");
			const numericValue = Number(rawValue);
			setValue("stakeAmount", numericValue);
		},
		[setValue],
	);

	const validateStakeAmount = useCallback(() => {
		if (!order || !marketData?.order || !accountData) return;

		let delegatable = 0;
		let minFill = 0;

		if (isMultiSig && multiSigAccount) {
			delegatable =
				order.resourceType === "ENERGY"
					? multiSigAccount.energy_delegatable
					: multiSigAccount.bandwidth_delegatable;
		} else {
			delegatable =
				order.resourceType === "ENERGY"
					? accountData.energy_delegatable
					: accountData.bandwidth_delegatable;
		}

		minFill =
			order.resourceType === "ENERGY"
				? marketData.order.minFillEnergy
				: marketData.order.minFillBandwidth;

		const remainingAmount =
			order.resourceAmount -
			Math.round(
				fromSun(Number(order.frozen)) /
					(order.resourceType === "ENERGY"
						? accountData.resource.energy.conversion_rate
						: accountData.resource.bandwidth.conversion_rate),
			);
		const maxFill = Math.max(0, remainingAmount);

		clearErrors("stakeAmount");

		// validate addresses
		if (signatureAddress !== "" && !WALLET_REGEX.test(signatureAddress || "")) {
			setError("signatureAddress", {
				type: "manual",
				message: t("invalidAddress"),
			});
		} else {
			clearErrors("signatureAddress");
		}

		if (WALLET_REGEX.test(payoutAddress)) {
			clearErrors("payoutAddress");
		} else {
			setError("payoutAddress", {
				type: "manual",
				message: t("invalidAddress"),
			});
		}

		// If the remaining amount is less than minFill, set the only option to the remaining amount
		if (remainingAmount > 0 && remainingAmount < minFill) {
			setValue("stakeAmount", remainingAmount);
			return;
		}

		// Check if stake amount is within the valid range
		if (stakeAmount < minFill || stakeAmount > maxFill) {
			setError("stakeAmount", {
				type: "manual",
				message: t("stakeAmountRange", { min: minFill, max: maxFill }),
			});
		}

		// Check if there's sufficient balance
		if (stakeAmount > delegatable) {
			setError("stakeAmount", {
				type: "manual",
				message: t("insufficientResources", { max: delegatable }),
			});
		}
	}, [
		order,
		marketData,
		isMultiSig,
		multiSigAccount,
		payoutAddress,
		accountData,
		stakeAmount,
		signatureAddress,
		setError,
		clearErrors,
		setValue,
		t,
	]);

	useEffect(() => {
		validateStakeAmount();
	}, [validateStakeAmount]);

	const calculateTrxAmount = useCallback(
		(amount: number) => {
			if (!order || !accountData) return 0;
			const conversionRate =
				order.resourceType === "ENERGY"
					? accountData?.resource.energy.conversion_rate
					: accountData?.resource.bandwidth.conversion_rate;
			return Math.ceil(amount * conversionRate);
		},
		[order, accountData],
	);

	const calculateReward = useCallback(
		(partialAmount: number) => {
			if (!order?.resourceAmount || !marketData?.order?.fillOrderAward) {
				console.warn("Missing data for reward calculation:", {
					order,
					marketData,
				});
				return 0;
			}

			const fraction = partialAmount / order.resourceAmount;
			const reward =
				Number(order.payment) * fraction * marketData.order.fillOrderAward;

			return fromSun(reward);
		},
		[order, marketData],
	);

	const onSubmit = handleSubmit(async (data) => {
		console.log("Submitting order", data);
		if (!order || !address || !connected) return;
		try {
			let tx: Transaction<ContractParamter>;
			let signedTx: SignedTransaction;
			if (data.isMultiSig && data.signatureAddress && data.permissionId) {
				console.log("Filling multisig order");
				tx = await tronWeb.transactionBuilder.delegateResource(
					toSun(calculateTrxAmount(data.stakeAmount)),
					order.targetAddress,
					order.resourceType,
					data.signatureAddress,
					true,
					order.duration / 3,
					{
						permissionId: data.permissionId,
					},
				);
				signedTx = await window.tronLink?.tronWeb.trx.multiSign(tx);
			} else {
				tx = await tronWeb.transactionBuilder.delegateResource(
					toSun(calculateTrxAmount(data.stakeAmount)),
					order.targetAddress,
					order.resourceType,
					address,
					true,
					order.duration / 3,
				);
				signedTx = await signTransaction(tx);
			}

			const result = await tronWeb.trx.sendRawTransaction(signedTx);

			if (result.result) {
				const res = await api.orders.fulfill.post(
					{
						orderId: order.id,
						tx: signedTx.txID,
						signature: signedTx.signature,
						payoutAddress: data.payoutAddress,
					},
					{
						headers: {
							"x-wallet": address,
						},
					},
				);
				if (res.status === 200) {
					queryClient.invalidateQueries({
						queryKey: [TABLE_QUERY_KEY],
					});
					queryClient.invalidateQueries({
						queryKey: [TRON_ACCOUNT_KEY],
					});
					openDialog("result", {
						success: true,
						message: t("orderFilled"),
						details: t("viewDetailsInReceipts"),
					});
				}
			} else {
				openDialog("result", {
					success: false,
					message: t("transactionFailed"),
					details: fromHex(result.message),
				});
				throw new Error("Transaction failed");
			}
		} catch (error) {
			toast.error(t("errorFillingOrder"));
			console.error("Error filling order:", error);
		}
	});

	if (marketDataLoading || orderLoading || !accountData) return <div />;
	if (!marketData?.order) return <div>{t("errorLoadingMarketData")}</div>;
	if (orderError || !order) return <div>{t("errorLoadingOrderDetails")}</div>;

	const minFill =
		order.resourceType === "ENERGY"
			? marketData.order.minFillEnergy
			: marketData.order.minFillBandwidth;

	const maxFill = Math.max(
		order.resourceAmount -
			Math.round(
				fromSun(Number(order.frozen)) /
					(order.resourceType === "ENERGY"
						? accountData.resource.energy.conversion_rate
						: accountData.resource.bandwidth.conversion_rate),
			),
		0,
	);

	return (
		<LayoutDialog
			isOpen={isOpen}
			onClose={onClose}
			icon={<RocketIcon className="h-8 w-8 text-primary-foreground" />}
			title={t("fillOrder")}
			onConfirm={onSubmit}
			confirmText={isSubmitting ? t("filling") : t("fillOrder")}
			confirmDisabled={!!errors.stakeAmount || isSubmitting}
		>
			<form onSubmit={onSubmit} className="grid gap-4 py-4">
				<div className="space-y-2">
					<Label htmlFor="stakeAmount" className="flex items-center gap-1">
						{t("toDelegateAmount", { resourceType: order.resourceType })}
					</Label>
					<div className="flex items-center ">
						<Controller
							name="stakeAmount"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									id="stakeAmount"
									type="text" // Changed from "number" to "text"
									min={Math.min(minFill, maxFill)}
									max={maxFill}
									className={`${errors.stakeAmount ? "border-red-500" : ""}${
										order.settings?.partialFill || maxFill < minFill
											? " rounded-r-none"
											: ""
									}`}
									disabled={!order.settings?.partialFill || maxFill < minFill}
									onChange={handleResourceAmountChange}
									value={field.value.toLocaleString()} // Format the display value
								/>
							)}
						/>
						{order.settings?.partialFill && maxFill >= minFill && (
							<Button
								type="button"
								onClick={() => setValue("stakeAmount", maxFill)}
								className="rounded-l-none text-xs"
							>
								{t("max")}
							</Button>
						)}
					</div>
					{errors.stakeAmount && (
						<p className="text-red-500 text-sm">{errors.stakeAmount.message}</p>
					)}
					{order.settings?.partialFill && maxFill >= minFill && (
						<p className="text-gray-500 text-xs">
							{t("min")}: {Math.min(minFill, maxFill).toLocaleString()}, {t("max")}:{" "}
							{maxFill.toLocaleString()}
						</p>
					)}
				</div>
				<div className="space-y-2">
					<Label htmlFor="payoutAddress" className="flex items-center gap-1">
						{t("payoutTargetAddress")}
					</Label>
					<Controller
						name="payoutAddress"
						control={control}
						render={({ field }) => (
							<div className="relative w-full">
								<Input {...field} id="payoutAddress" className="pr-10" />
								<WalletMinimal className="absolute top-2.5 right-2 h-5 w-5 text-muted-foreground" />
							</div>
						)}
					/>
					{errors.payoutAddress && (
						<p className="text-red-500 text-sm">{errors.payoutAddress.message}</p>
					)}
				</div>
				<div className="space-y-2">
					<div className="flex items-center space-x-2">
						<Controller
							name="isMultiSig"
							control={control}
							render={({ field }) => (
								<Checkbox
									id="isMultiSig"
									checked={field.value}
									onCheckedChange={(checked) => {
										field.onChange(checked);
									}}
								/>
							)}
						/>
						<Label htmlFor="isMultisig">{t("multisignature")}</Label>
					</div>
				</div>

				{isMultiSig && (
					<>
						<div className="space-y-2">
							<Label htmlFor="signatureAddress">{t("signatureAddress")}</Label>
							<Controller
								name="signatureAddress"
								control={control}
								render={({ field }) => <Input {...field} id="signatureAddress" />}
							/>
							{errors.signatureAddress && (
								<p className="text-red-500 text-sm">
									{errors.signatureAddress.message}
								</p>
							)}
						</div>
						<div className="space-y-2">
							<Label htmlFor="permissionId">{t("permission")}</Label>
							<Controller
								name="permissionId"
								control={control}
								render={({ field }) => (
									<Select
										onValueChange={(val) => field.onChange(Number(val))}
										defaultValue={multiSigAccount?.permissions[0]?.id.toString() || ""}
									>
										<SelectTrigger>
											<SelectValue placeholder={t("selectPermission")} />
										</SelectTrigger>
										<SelectContent>
											{multiSigAccount?.permissions.some(
												(perm) => perm.type === "owner",
											) && (
												<SelectGroup>
													<SelectLabel>{t("ownerPermission")}</SelectLabel>
													{multiSigAccount.permissions
														.filter((perm) => perm.type === "owner")
														.map((perm) => (
															<SelectItem key={perm.id} value={perm.id.toString()}>
																{perm.name}
															</SelectItem>
														))}
												</SelectGroup>
											)}
											{multiSigAccount?.permissions.some(
												(perm) => perm.type === "active",
											) && (
												<SelectGroup>
													<SelectLabel>{t("activePermission")}</SelectLabel>
													{multiSigAccount.permissions
														.filter((perm) => perm.type === "active")
														.map((perm) => (
															<SelectItem key={perm.id} value={perm.id.toString()}>
																{perm.name}
															</SelectItem>
														))}
												</SelectGroup>
											)}
										</SelectContent>
									</Select>
								)}
							/>
						</div>
					</>
				)}
				<Separator />
				<OrderSummary
					resourceType={order.resourceType}
					stakeAmount={stakeAmount}
					duration={order.duration}
					calculateReward={calculateReward}
					calculateTrxAmount={calculateTrxAmount}
				/>
			</form>
		</LayoutDialog>
	);
};

interface OrderSummaryProps {
	resourceType: string;
	stakeAmount: number;
	duration: number;
	calculateReward: (amount: number) => number;
	calculateTrxAmount: (amount: number) => number;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
	resourceType,
	stakeAmount,
	duration,
	calculateReward,
	calculateTrxAmount,
}) => {
	const [t] = useTranslation();
	return (
		<div className="space-y-2">
			{resourceType === "ENERGY" ? (
				<SummaryRow
					label={t("energy")}
					value={`${stakeAmount.toLocaleString()} `}
					highlight={true}
					icon={<LightningBoltIcon className="h-4 w-4" />}
				/>
			) : (
				<SummaryRow
					label={t("bandwidth")}
					value={`${stakeAmount.toLocaleString()} `}
					highlight={true}
					icon={<Gauge className="h-4 w-4" />}
				/>
			)}

			<SummaryRow label={t("duration")} value={formatDuration(duration)} />

			<Separator />
			<SummaryRow
				label={t("totalPayout")}
				value={`+${calculateReward(stakeAmount).toLocaleString()} TRX`}
				highlight
				bold
			/>
			<SummaryRow
				label={t("trxToDelegate")}
				value={`${calculateTrxAmount(stakeAmount).toLocaleString()} TRX`}
				highlight
			/>
		</div>
	);
};

interface SummaryRowProps {
	label: string;
	value: string;
	icon?: React.ReactNode;
	highlight?: boolean;
	bold?: boolean;
}

const SummaryRow: React.FC<SummaryRowProps> = ({
	label,
	value,
	icon,
	highlight,
	bold,
}) => (
	<div className="flex justify-between">
		<span className="flex items-center gap-1">{label}</span>
		<span
			className={clsx(
				highlight
					? "flex items-center gap-1 text-primary"
					: "flex items-center gap-1",
				bold ? "font-bold" : "",
			)}
		>
			{value}
			{icon}
		</span>
	</div>
);
