import type React from "react";
import { useState } from "react";
import { api } from "@/lib/api";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";

interface AdminUserFormProps {
	onUserCreated: (user: any) => void;
}

export const AdminUserForm: React.FC<AdminUserFormProps> = ({
	onUserCreated,
}) => {
	const [wallet, setWallet] = useState("");
	const [role, setRole] = useState("USER");
	const [priceFor5Min, setPriceFor5Min] = useState("");
	const [priceFor15Min, setPriceFor15Min] = useState("");
	const [priceFor1H, setPriceFor1H] = useState("");
	const [name, setName] = useState("");
	const [minResource, setMinResource] = useState("60000");

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const adminPassword = localStorage.getItem("admin-password");

		if (!adminPassword) {
			toast.error("Admin authentication required");
			return;
		}

		try {
			const response = await api.users.create.post(
				{
					wallet,
					name,
					role,
					priceFor5Min: Number(priceFor5Min),
					priceFor15Min: Number(priceFor15Min),
					priceFor1H: Number(priceFor1H),
				},
				{
					headers: {
						"x-password": adminPassword,
					},
				},
			);
			if (response.status === 200) {
				onUserCreated(response.data);
				toast.success("User created");
				setWallet("");
				setRole("USER");
				setPriceFor5Min("");
				setPriceFor15Min("");
				setPriceFor1H("");
				setMinResource("60000");
				setName("");
			} else toast.error(`Error creating user, ERROR: ${response.status}`);
		} catch (error) {
			toast.error("Error creating user");
		}
	};

	return (
		<Card>
			<CardHeader>
				<CardTitle>Create New User</CardTitle>
			</CardHeader>
			<CardContent>
				<form onSubmit={handleSubmit} className="space-y-4">
					<div className="grid md:grid-cols-2 gap-4">
						<div className="space-y-2">
							<Label htmlFor="name">Name</Label>
							<Input
								id="name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
							/>
						</div>
						<div className="space-y-2">
							<Label htmlFor="wallet">Wallet</Label>
							<Input
								id="wallet"
								value={wallet}
								onChange={(e) => setWallet(e.target.value)}
								required
							/>
						</div>
					</div>

					<div className="space-y-2">
						<Label htmlFor="role">Role</Label>
						<Select value={role} onValueChange={setRole}>
							<SelectTrigger>
								<SelectValue placeholder="Select role" />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="USER">User</SelectItem>
								<SelectItem value="ADMIN">Admin</SelectItem>
							</SelectContent>
						</Select>
					</div>

					<div className="grid grid-cols-3 gap-4">
						<div className="space-y-2">
							<Label htmlFor="price5min">5 Min Price</Label>
							<Input
								id="price5min"
								type="number"
								value={priceFor5Min}
								onChange={(e) => setPriceFor5Min(e.target.value)}
								required
							/>
						</div>

						<div className="space-y-2">
							<Label htmlFor="price15min">15 Min Price</Label>
							<Input
								id="price15min"
								type="number"
								value={priceFor15Min}
								onChange={(e) => setPriceFor15Min(e.target.value)}
								required
							/>
						</div>

						<div className="space-y-2">
							<Label htmlFor="price1h">1 Hour Price</Label>
							<Input
								id="price1h"
								type="number"
								value={priceFor1H}
								onChange={(e) => setPriceFor1H(e.target.value)}
								required
							/>
						</div>

						<div className="space-y-2">
							<Label htmlFor="minResource">Minimum Resource</Label>
							<Input
								id="minResource"
								type="number"
								value={minResource}
								onChange={(e) => setMinResource(e.target.value)}
								required
							/>
						</div>
					</div>

					<Button type="submit" className="w-full">
						Create User
					</Button>
				</form>
			</CardContent>
		</Card>
	);
};
