import { AdminMarketDataForm } from "@/components/adminMarketDataForm";
import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

export const Route = createFileRoute("/adminMarketData")({
	component: AdminMarketDataPage,
});

function AdminMarketDataPage() {
	const { t } = useTranslation();
	return (
		<div>
			<h1 className="mb-4 font-bold text-2xl text-primary">
				{t("adminMarketDataManagement")}
			</h1>
			<AdminMarketDataForm />
		</div>
	);
}
export default AdminMarketDataPage;
