import type React from "react";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

interface TransactionDialogProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm?: () => void;
	icon: React.ReactNode;
	title: string;
	children: React.ReactNode;
	confirmText: string;
	confirmDisabled?: boolean;
	removeCancelButton?: boolean;
}

export const LayoutDialog: React.FC<TransactionDialogProps> = ({
	isOpen,
	onClose,
	onConfirm,
	icon,
	title,
	children,
	confirmText,
	confirmDisabled,
	removeCancelButton,
}) => {
	const { t } = useTranslation();

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<div className="mx-auto mb-4 rounded-full bg-primary p-4">{icon}</div>
					<DialogTitle className="text-center font-bold text-2xl">
						{t(title)}
					</DialogTitle>
				</DialogHeader>
				{children}
				<DialogFooter>
					<Button
						className="mr-2 flex-grow"
						onClick={onConfirm}
						disabled={confirmDisabled}
					>
						{t(confirmText)}
					</Button>
					{!removeCancelButton && (
						<Button variant="outline" className="ml-2 flex-grow" onClick={onClose}>
							{t("Cancel")}
						</Button>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
